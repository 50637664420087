<template>
	<div v-loading="loading" class="Contact bgfff br4 p32">
		<div class="nav fs16 fw-7 font-33 pb24 pr00 flex">
			<div class="tit-line flex">
				<span class="pr">联系我们</span>
				<span class="line">联系我们</span>
			</div>
		</div>

		<div class="info flex">
			<img :src="$config.OSS_IMG_BASE_URL + 'webimages/' + 'connect-bg.png'" />
			<div class="right font-33 fs18">
				<div class="fs20 fw-7 ptb32">好好帮</div>
				<div class="mb24">官网：{{ website }}</div>
				<div class="mb24">公司邮箱：{{ company_email }}</div>
				<div class="">公司地址：{{ company_address }}</div>
			</div>
		</div>

		<div class="list flex flex-wrap">
			<!-- 呼叫客服 start -->
			<div>
				<div
					class="item flex plr32 ptb24 br16 mt32"
					style="background: #fffaef"
				>
					<img class="mr24" src="@/assets/other/connect-mobile.png" />
					<div class="item-con">
						<div class="con-top flex justify-between align-center">
							<span class="fs18 font-33">呼叫客服：</span>
							<div>
								<div class="fs16 font-33">{{ kefu_mobile }}</div>
							</div>
						</div>
						<div class="mt16 fs18 font-33">{{ kefu_mobile_time }}</div>
					</div>
				</div>
			</div>
			<!-- 呼叫客服 end -->

			<!-- 在线客服 start -->
			<div>
				<div
					class="item flex plr32 ptb24 br16 mt32"
					style="background: #fff9f5"
				>
					<img class="mr24" src="@/assets/other/connect-service.png" />
					<div class="item-con">
						<div class="con-top flex justify-between align-center">
							<span class="fs18 font-33">在线客服：</span>
							<div>
								<div class="fs18 font-33">{{ kefu_online_time }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 在线客服 end -->

			<!-- 官方微信 start -->
			<div>
				<div
					class="item flex plr32 ptb24 br16 mt32"
					style="background: #f4fff8"
				>
					<img class="mr24" src="@/assets/other/connect-wx.png" />
					<div class="item-con">
						<div class="con-top">
							<span class="fs18 font-33">官方微信：</span>
							<div class="mt24">
								<div class="fs18 fw-5 font-33">
									{{ wx_one }}
									<span
										class="fs16 fw-7 ml32 cursor"
										v-clipboard:copy="wx_one"
										v-clipboard:success="copySuccess"
										v-clipboard:error="copyError"
										style="color: #4177dd"
										>复制</span
									>
								</div>
								<div class="fs18 fw-5 font-33 mt16">
									{{ wx_two }}
									<span
										class="fs16 fw-7 ml32 cursor"
										v-clipboard:copy="wx_two"
										v-clipboard:success="copySuccess"
										v-clipboard:error="copyError"
										style="color: #4177dd"
										>复制</span
									>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 官方微信 end -->

			<!-- 官方QQ start -->

			<div>
				<div
					class="item flex plr32 ptb24 br16 mt32"
					style="background: #f7fbff"
				>
					<img class="mr24" src="@/assets/other/connect-qq.png" />
					<div class="item-con">
						<div class="con-top align-center">
							<span class="fs18 font-33">官方QQ：</span>
							<div class="mt24">
								<div class="fs18 fw-5 font-33">
									{{ qq_one }}
									<span
										class="fs16 fw-7 ml32 cursor"
										v-clipboard:copy="qq_one"
										v-clipboard:success="copySuccess"
										v-clipboard:error="copyError"
										style="color: #4177dd"
										>复制</span
									>
								</div>
								<div class="fs18 fw-5 font-33 mt16">
									{{ qq_two }}
									<span
										class="fs16 fw-7 ml32 cursor"
										v-clipboard:copy="qq_two"
										v-clipboard:success="copySuccess"
										v-clipboard:error="copyError"
										style="color: #4177dd"
										>复制</span
									>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 官方QQ end -->
		</div>
	</div>
</template>
<script>
export default {
	name: "Contact",
	data() {
		return {
			loading: true,

			website: "", // 官网
			company_email: "", // 公司邮箱
			company_address: "", // 公司地址
			kefu_mobile: "", // 呼叫客服 客服电话
			kefu_mobile_time: "", // 呼叫客服 客服在线时间
			kefu_online_time: "", // 在线客服 时间
			wx_one: "",
			wx_two: "",
			qq_one: "",
			qq_two: "",
		};
	},
	created() {
		this.$pageScrollTop();

		this.$API
			.connectAjax()
			.then((res) => {
				this.loading = false;
				if (res.code == 1) {
					Object.keys(res.data).forEach((item) => {
						this[item] = res.data[item];
					});
				} else {
					this.$message.info(res.msg);
				}
			})
			.catch((err) => {
				console.log("err", err);
			});
	},
	methods: {
		copySuccess() {
			this.$message.success("复制成功");
		},
		copyError() {
			this.$message.error("复制失败");
		},
	},
};
</script>
<style scoped lang="scss">
.Contact {
	@extend .w100;
	@extend .h100;

	.nav {
		border-bottom: 1px dashed #eeeeee;
	}

	.info {
		align-items: flex-end;
		padding-bottom: 40px;
		border-bottom: 1px solid #eeeeee;

		img {
			margin-left: 64px;
			width: 260px;
			height: 172px;
		}

		.right {
			padding-left: 76px;
		}
	}

	.list {
		> div {
			width: 50%;

			&:nth-child(odd) {
				padding-right: 16px;
			}

			&:nth-child(even) {
				padding-left: 16px;
			}
		}

		.item {
			img {
				width: 60px;
				height: 60px;
			}

			.item-con {
				flex: 1;
			}
		}
	}
}
</style>
